.custom-container{
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width:1200px) {
  .steps-ui::before {
    content: '';
    background: linear-gradient(90deg, rgba(54, 54, 54, 0) 0%, #363636 52.5%, rgba(54, 54, 54, 0) 100%);
    position: absolute;
    width: 185%;
    left: 151px;
    right: 0;
    z-index: 9999;
    top: 50px;
    height: 2px;
  }
  .steps-ui:last-child::before{
    display: none;
  }
  
}

.tab-active:before,
.tab-active:after {
  content: "";
  position: absolute;

  height: 10px;
  width: 20px;

  bottom: 0;
}

.tab-active:after, .tab-normal:hover:after {
  right: -20px;
  border-radius: 0 0 0 10px;
  -moz-border-radius: 0 0 0 10px;
  -webkit-border-radius: 0 0 0 10px;
  -webkit-box-shadow: -10px 0 0 0 #22292f;
  box-shadow: -10px 0 0 0 #22292f;
}

.tab-active:before, .tab-normal:hover:before  {
  left: -20px;
  border-radius: 0 0 10px 0;
  -moz-border-radius: 0 0 10px 0;
  -webkit-border-radius: 0 0 10px 0;
  -webkit-box-shadow: 10px 0 0 0 #22292f;
  box-shadow: 10px 0 0 0 #22292f;
}

.tab-active:last-child:after{
  display: none;
}
.tab-active:first-child:before{
  display: none;
}
.form-bg-landing{
      background-color: rgba(255, 255, 255, 0.08);
}